import { getTimezoneOffset } from 'date-fns-tz';
import { parse, format } from 'date-fns';
const axios = require('axios');

export const timedLocalStorage = {
  get(keyName) {
    const data = localStorage.getItem(keyName);
    if (!data) {
      // if no value exists associated with the key, return null
      return null;
    }

    const item = JSON.parse(data);

    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
      localStorage.removeItem(keyName);
      return null;
    }

    // return data if not expired
    return item.value;
  },
  set(keyName, keyValue, ttl) {
    const data = {
      ttl: Date.now() + ttl * 1000,
      value: keyValue
    };
    localStorage.setItem(keyName, JSON.stringify(data));
  }
};

/**
 * Alias for axios()
 * @returns
 */
export const fetch = async ({ body, headers = {}, method, params = {}, url }) => {
  return await axios({
    data: body,
    headers: { 'content-type': 'application/json', ...headers },
    method: method || 'get',
    params: params,
    url: url
  }).catch((error) => {
    throw new Error(error);
  });
};

/**
 * Perform a fetch, return the body
 * @param String url
 * @returns JSON
 */
export const fetcher = async (url) => {
  return await fetch({ url }).then((res) => res.data);
};

export const formatMoney = (value) => {
  if (isNaN(value)) {
    return;
  }
  return (
    '$' +
    Math.round(value)
      .toString()
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  );
};

export const formatPercent = (value) => {
  if (isNaN(value)) {
    return;
  }

  return parseFloat(value).toFixed(1) + '%';
};

export const formatNumber = (value) => {
  return value.toLocaleString('en-US');
};

/**
 * @param {*} array
 * @param {*} callback
 */
export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

/**
 *
 * @param {any} input
 * @returns {any} deepcloned Copy of the input
 */
export const deepClone = (input) => {
  return JSON.parse(JSON.stringify(input));
};

/**
 * Returns array containing thing
 * @param {any} thing
 * @returns Array
 */
export const toArray = (thing) => (Array.isArray(thing) ? thing : [thing]);

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const formatPublishDate = (value) => {
  const options = { day: 'numeric', month: 'long', weekday: 'long', year: 'numeric' };
  const date = new Date(value);

  return date.toLocaleDateString('en-US', options);
};

export const toShortDate = (value) => {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const date = new Date(value);

  return date.toLocaleDateString('en-US', options);
};

/**
 * Returns the intersection of two objects, o2 takes precedence
 * @param Object
 * @param Object
 * @returns
 */
export const getIntersection = (o1, o2) => {
  const [k1, k2] = [Object.keys(o1), Object.keys(o2)];
  const [first, next] = k1.length > k2.length ? [k2, o1] : [k1, o2];
  const newObj = {};
  const keys = first.filter((k) => k in next);
  keys.forEach((k) => {
    if (o1[k] !== o2[k]) {
      newObj[k] = o2[k];
    }
  });
  return newObj;
};

/**
 * Takes in an absolute url and returns the pathname only
 * @param String url
 * @returns String
 */
export const getSlug = (url) => {
  if (url && url.slice(0, 4) === 'http') {
    return new URL(url).pathname;
  }
};

export const shortPriceFormatter = (num) => {
  if (num > 999999) {
    return '$' + Math.sign(num) * (Math.abs(num) / 1000000).toFixed(2) + 'm';
  }

  if (num > 999) {
    return '$' + Math.floor(Math.sign(num) * (Math.abs(num) / 1000)) + 'k';
  }

  return '$' + num;
};

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
};

export const toKebabCase = (string) => {
  return string.replace(/[\s_/]+/g, '-').toLowerCase();
};

export const shuffleArrayInPlace = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const copyToClipboard = (str) => {
  if (navigator?.clipboard?.writeText) return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
};

export const getSiteURL = () => {
  let hostname;

  // Set hostname dependent on environment, branch and vercel url
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    hostname = 'https://www.sibcycline.com';
  } else if (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'staging') {
    hostname = 'https://beta.sibcycline.com';
  } else if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    hostname = `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  } else {
    hostname = 'http://localhost:3000';
  }

  return hostname;
};

export const truncateMarketingRemarks = (remarks) => {
  const maxLength = 155;
  if (remarks.length <= maxLength) {
    return remarks;
  }

  const truncated = remarks.slice(0, maxLength);
  const lastPeriodIndex = truncated.lastIndexOf('.');

  return lastPeriodIndex !== -1 ? truncated.slice(0, lastPeriodIndex + 1) : truncated;
};

export const captchaVerify = async () => {
  try {
    const token = await window.grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY, {
      action: 'submit'
    });
    return token;
  } catch (error) {
    console.error('Error executing reCAPTCHA:', error);
    return null;
  }
};

export const thisYear = () => {
  return new Date().getFullYear();
};

/**
 * Utility function to truncate a listing name to a specified length.
 * Adds a suffix to the listing name if it fits within the specified length.
 * Handles cases where the listing name is too long and needs to be truncated.
 * Ensures the listing name doesn't cut off mid-word or mid-address.
 *
 * @param {string} address - The address part of the listing name.
 * @param {string} city - The city part of the listing name.
 * @param {string} state - The state part of the listing name.
 * @param {string} zip - The ZIP code part of the listing name.
 * @returns {string} - The truncated listing name.
 */
export const truncateListingName = (address, city, state, zip) => {
  const maxLength = 60;
  const suffixFull = ' | Sibcy Cline REALTORS®';
  const suffixShort = ' | Sibcy Cline';

  let baseListingName = `${address}, ${city}, ${state} ${zip}`;

  // Try to return the full listing name with the full suffix if it fits
  if ((baseListingName + suffixFull).length <= maxLength) {
    return baseListingName + suffixFull;
  }

  // Try to return the full listing name with the short suffix if it fits
  if ((baseListingName + suffixShort).length <= maxLength) {
    return baseListingName + suffixShort;
  }

  // If the base listing name fits within maxLength, return it
  if (baseListingName.length <= maxLength) {
    return baseListingName;
  }

  // Remove the ZIP code and check the length
  let truncated = `${address}, ${city}, ${state}`;
  if (truncated.length <= maxLength) {
    return truncated;
  }

  // Remove the state and check the length
  truncated = `${address}, ${city}`;
  if (truncated.length <= maxLength) {
    return truncated;
  }

  // Remove the city and check the length
  truncated = address;
  if (truncated.length <= maxLength) {
    return truncated;
  } else {
    // Edge Case function to truncate a address and add '...' if necessary
    const truncateString = (str, maxLen) => {
      return str.length > maxLen ? `${str.slice(0, maxLen - 3)}...` : str;
    };

    return truncateString(address, maxLength);
  }
};

/**
 * Processes the meta title to fit within a specified maximum length.
 * Appends a suffix if space allows
 *
 * @param {string} title - The original title to process.
 * @returns {string} - The processed title.
 */
export const processMetaTitle = (title) => {
  const suffixFull = ' | Sibcy Cline REALTORS®';
  const suffixShort = ' | Sibcy Cline';
  const maxLength = 60;

  // Handle edge case where title includes either suffix
  if (title.includes(suffixFull) || title.includes(suffixShort)) {
    // remove the suffix from the title
    title = title.replace(suffixFull, '').replace(suffixShort, '').trim();
  }

  // Try to return the full title with the full suffix if it fits
  if ((title + suffixFull).length <= maxLength) {
    return title + suffixFull;
  } else {
    return title + suffixShort;
  }
};

export const toIsoString = (d, t) => {
  const date = String(d);
  if (!d || !t || d === 'false') {
    return null;
  } else {
    const dateParts = date.split(' 00');
    const dateString = `${dateParts[0]} ${t}`;
    const isoString = new Date(dateString).toISOString();
    return isoString;
  }
};

/**
 * Converts a string to a slug by removing special characters and replacing spaces with hyphens.
 *
 * @param {string} text - The string to be converted to a slug.
 * @returns {string} - The slugified string.
 */
export const slugify = (text) => {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};

export const isDaylightSavingTime = (date) => {
  const timeZone = 'America/New_York';
  const offset = getTimezoneOffset(timeZone, date);
  return offset === -240; // -240 minutes = -04:00 hours (Eastern Daylight Time)
};

// Function to determine the year based on the given date
export const determineYear = (dateString) => {
  const today = new Date();
  const thisYear = today.getFullYear();
  const dateThisYear = parse(dateString, 'MMM dd', new Date(thisYear, 0, 1));

  // If it's November or December and the event is in January/February, use next year
  if ((today.getMonth() === 10 || today.getMonth() === 11) && dateThisYear.getMonth() <= 1) {
    return thisYear + 1;
  }

  return thisYear;
};

/**
 * Parses a date string in the format "Day, MMM DD, startTime - endTime" and returns a structured object.
 *
 * @param {string} input - The input string to parse, expected to be in the format
 *                         "Day, MMM DD, startTime - endTime" (e.g., "Fri, Nov 15, 12pm - 5pm").
 *
 * @returns {Object} - An object containing the parsed and formatted date and time components:
 *  - `startDate` (string): The ISO date string (e.g., "2023-11-15").
 *  - `endDate` (string): The same ISO date string (matches `startDate` since times are on the same day).
 *  - `startTime` (string): The start time in 24-hour format (e.g., "12:00").
 *  - `endTime` (string): The end time in 24-hour format (e.g., "17:00").
 *
 * @throws {Error} - Throws an error if the input is not in the expected format or the date cannot be parsed.
 */
export const parseDateWithFormatMMMDDAndTime = (input) => {
  const parts = input.split(', '); // Splits "Fri, Nov 15, 12pm - 5pm" into parts

  if (parts.length < 3) {
    throw new Error('Incorrect format. Expected input: "Day, MMM DD, startTime - endTime"');
  }

  const datePart = parts[1].trim(); // "Nov 15"
  const timeRange = parts[2].trim(); // "12pm - 5pm"
  const [startTime, endTime] = timeRange.split(' - ').map((time) => time.trim());

  // Handles both hours only and hours:minutes formats
  const parseFormat = (time) => {
    return time.includes(':') ? 'MMM dd yyyy h:mma' : 'MMM dd yyyy hha';
  };

  const year = determineYear(datePart);
  const fullStartDateTimeStr = `${datePart} ${year} ${startTime}`;
  const fullEndDateTimeStr = `${datePart} ${year} ${endTime}`;

  try {
    const startDateTime = parse(fullStartDateTimeStr, parseFormat(startTime), new Date());
    const endDateTime = parse(fullEndDateTimeStr, parseFormat(endTime), new Date());

    if (isNaN(startDateTime) || isNaN(endDateTime)) {
      throw new Error('Failed to parse one or both of the times.');
    }

    // Format dates as ISO strings
    const formattedDate = format(startDateTime, 'yyyy-MM-dd');
    const formattedStartTime = format(startDateTime, 'HH:mm');
    const formattedEndTime = format(endDateTime, 'HH:mm');

    return {
      startDate: formattedDate,
      endDate: formattedDate,
      startTime: formattedStartTime,
      endTime: formattedEndTime
    };
  } catch (error) {
    console.error('Date processing error:', error.message);
    throw error;
  }
};
