export default (input) => {
  return {
    ...input,
    listItems: {
      items: input?.listItems?.items
        ?.map((el) => {
          if (!el) return null;
          if (el.topicParentPath === 'HomeServices') {
            el.topicParentPath = 'home-services';
          }
          return {
            ...el,
            linkUrl:
              el?.linkUrl ||
              (el?.topicParentPath &&
                el?.topicPagePath &&
                `/${el?.topicParentPath?.toLowerCase()}/${el?.topicPagePath}`)
          };
        })
        ?.filter((el) => el)
    }
  };
};
