import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';

// ---------------------------------------------------------

import {
  dropdown_links_heading,
  dropdown_is_showing,
  dropdown_link_is_active,
  dropdown_menu_inner,
  dropdown_menu,
  dropdown,
  my_account_dropdown
} from './styles.module.scss';

// ---------------------------------------------------------

const Dropdown = (props) => {
  let { items, label, isFullNavigation, theme, menuIsOpen } = props;

  // ---------------------------------------------------------

  const [dropdownHeight, setDropdownHeight] = useState(isFullNavigation ? 0 : 'auto');
  const [clicked, setClicked] = useState(false);

  // ---------------------------------------------------------

  const classes = classNames(dropdown_menu, {
    [my_account_dropdown]: theme === 'my-account'
  });

  // ---------------------------------------------------------

  const handleMediaQueryChange = () => {
    if (isFullNavigation) {
      setDropdownHeight(0);
      setClicked(false);
    } else {
      setDropdownHeight('auto');
      setClicked(false);
    }
  };

  // ---------------------------------------------------------

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdownHeight(0);
        setClicked(false);
      }}
    >
      <button
        aria-expanded={dropdownHeight !== 0}
        aria-controls={label}
        className={classNames(dropdown, {
          [dropdown_is_showing]: clicked
        })}
        onClick={() => {
          handleMediaQueryChange;
          setClicked(!clicked);
        }}
      >
        {label}
        <Icon name="small-down" type="arrow" />
      </button>

      <AnimateHeight
        animateOpacity={true}
        className={classes}
        contentClassName={dropdown_menu_inner}
        duration={isFullNavigation ? 300 : 0}
        height={isFullNavigation && clicked ? 'auto' : menuIsOpen && !isFullNavigation ? 'auto' : 0}
        id={label}
      >
        <ul>
          {items.map((item, index) => {
            return (
              <Fragment key={index}>
                {item.heading && (
                  <li className={dropdown_links_heading}>
                    <span>{item.heading}</span>
                  </li>
                )}

                {item.children.map((child) => {
                  return (
                    child.url && (
                      <li key={`${child.label}`} className={child.className}>
                        {child.url.includes('logout') && <a href={child.url}>{child.label}</a>}
                        {!child.url.includes('logout') && (
                          <Link
                            // Temporarily hardcoding forceAnchorTag to true
                            // Will fix in a follow up task once prod is in a good state
                            forceAnchorTag={true}
                            activeClassName={dropdown_link_is_active}
                            url={child.url}
                            query={child.query}
                          >
                            {child.label}
                          </Link>
                        )}
                      </li>
                    )
                  );
                })}
              </Fragment>
            );
          })}
        </ul>
      </AnimateHeight>
    </OutsideClickHandler>
  );
};

Dropdown.propTypes = {
  /**
   * Specifies if the full navigation has been rendered
   */
  isFullNavigation: PropTypes.bool,

  /**
   * Specifies the dropdown elements
   */
  items: PropTypes.array.isRequired,

  /**
   * Specifies the dropdown label
   */
  label: PropTypes.string.isRequired,
  /**
   * Specifies if the mobile menu is open
   */
  menuIsOpen: PropTypes.bool,
  /**
   * Specifies the theme of the dropdown
   */
  theme: PropTypes.string
};

export default Dropdown;
